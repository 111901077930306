import React from 'react'
const SubAbout = () => {
    return (

        <div className="aboutAll">

            <div className="about_content ">
                <h2>I'm Osama Megahed</h2>
                <p className="p_about">
                    A student at AAST, studying Engineering, Electronics and communication
                    department , it's the third year to me at the college. I was so interested
                    about web development specially the front-end career so I started to learn
                    it for over than a year and I have accomplished many websites which are real
                    and virtual and I am so excited to start work with you all of this beside my college career in network and electronics
                </p>
            </div>
        </div>

    )
}

export default SubAbout
