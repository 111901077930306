import React from 'react'
import "./About.scss"
const Hello = () => {
    return (

        <div className="aboutAll">

            <div className="about_content ">
                <h2>Nice 2 meet u</h2>
                <p className="p_about">
                    Hello, it's Osama, a Front-end developer for over than a year,
                    I have developed many websites and web Apps, would be an excellent
                    choice for your organization or project, I can make all your
                    ideas come to the real world, In addition to my knowledge bases,
                    I actively seek out new technologies and stay up-to-date on the
                    industry trends and advancements. I can be reached anytime.
                </p>

            </div>
        </div>

    )
}

export default Hello
