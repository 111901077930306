import childCave from "../assets/designes/childCave.jpg"
import crackedGirl from "../assets/designes/crackedGirl.png"
import cyborg from "../assets/designes/cyborg.jpg"
import fireFlower from "../assets/designes/fireFlower.png"
import gamingPoster from "../assets/designes/gamingPoster.png"
import glass from "../assets/designes/glass.png"
import img11 from "../assets/designes/img11.jpg"
import img12 from "../assets/designes/img12.png"
import img13 from "../assets/designes/img13.png"
import img14 from "../assets/designes/img14.png"
import img15 from "../assets/designes/img15.png"
import moonBoy from "../assets/designes/moonBoy.jpg"
import neonGirl from "../assets/designes/neonGirl.png"
import PATRONUS from "../assets/designes/PATRONUS.jpg"
import queen from "../assets/designes/queen.jpg"
import spaceGirl from "../assets/designes/spaceGirl.jpg"
import storm from "../assets/designes/storm.png"
import coloredFace from "../assets/designes/coloredFace.png"
import triangleGirl from "../assets/designes/triangleGirl.png"
import sandFace from "../assets/designes/sandFace.jpg"
import crackedSoul from "../assets/designes/crackedSoul.png"
import glassGirl from "../assets/designes/glassGirl.png"
import ball from "../assets/designes/ball.png"
import bird from "../assets/designes/bird.png"
import amir from "../assets/designes/amir.jpg"
import like_girl from "../assets/designes/like_girl.jpg"
import girl_in_street from "../assets/designes/girl_in_street.jpg"
import Angel_girl from "../assets/designes/Angel_girl.png"
import raven from "../assets/designes/Raven.png"

const DesignesData =[
    {img:spaceGirl,  name: "spaceGirl"},
    {img:sandFace, name: "sandFace"},
    {img:PATRONUS, name: "PATRONUS"},
    {img:cyborg, name: "cyborg"},
    {img:neonGirl, name: "neonGirl"},
    {img:storm, name: "storm"},
    {img:moonBoy, name: "moonBoy"},
    {img:gamingPoster, class: "hide ",name: "gamingPoster"},
    {img:triangleGirl, class: "hide ",name: "triangleGirl"},
    {img:coloredFace, class: "hide ",name: "coloredFace"},
    {img:glass, class: "hide ",name: "glass"},
    {img:ball, class: "hide ",name: "ball"},
    {img:crackedGirl, class: "hide ",name: "crackedGirl"},
    {img:childCave, class: "hide ",name: "childCave"},
    {img:img11, class: "hide ",name: "img11"},
    {img:img12, class: "hide ",name: "img12"},
    {img:img13, class: "hide ",name: "img13"},
    {img:img14, class: "hide ",name: "img14"},
    {img:img15, class: "hide ",name: "img15"},
    {img:queen, class: "hide ",name: "queen"},
    {img:crackedSoul, class: "hide ",name: "crackedSoul"},
    {img:glassGirl, class: "hide ",name: "glassGirl"},
    {img:bird, class: "hide ",name: "bird"},
    {img:fireFlower, class: "hide ",name: "fireFlower"},
    {img:like_girl, class: "hide ",name: "like_girl"},
    {img:amir, class: "hide ",name: "amir"},
    {img:girl_in_street, class: "hide ",name: "girl_in_street"},
    {img:Angel_girl, class: "hide ",name: "Angel_girl"},
    {img:raven, class: "hide ",name: "raven"},
]
export default DesignesData


































